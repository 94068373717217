import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { OAuthUtilService } from '../services/oauth-util.service';
import { AppConfigService } from '../services/app-config.json.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private routes: Router,
    private oauthService: OAuthService,
    private appConfigService: AppConfigService,
    private oAuthUtilService: OAuthUtilService
  ) {
    const r = this.appConfigService.getConfig().tenantId;
    console.log('tenantId 3: ',r );
}

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._check();
    }

  private _check(): Observable<boolean>{
    if ( this.oauthService.getAccessToken()!= null && this.oauthService.hasValidAccessToken() ) {
        return of(true);
      }
      else {
        this.routes.navigate([ this.appConfigService.getConfig().tenantId + '/login']);
        return of(false);
      }
    }

}
