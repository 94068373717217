import { DatePipe } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxMaskModule } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslocoRootModule } from './transloco-root.module';
import { environment } from 'src/environments/environment';
import {
  AgrioMobileCrmClientConfiguration,
  AgrioMobileCrmClientClientModule,
} from '@agriofinans/mobile-crm-client';
import {
  AgrioPublicCatalogsClientClientModule,
  AgrioPublicCatalogsClientConfiguration,
} from '@agriofinans/public-catalogs-client';

import { OAuthModule } from 'angular-oauth2-oidc';
import { AppConfig } from './core/config/app.config';
import { AppConfigService } from './services/app-config.json.service';


const appInitializerFn = (appConfig: AppConfigService) => () =>appConfig.loadAppConfig();

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    TranslocoRootModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    AgrioMobileCrmClientClientModule.forRoot(agrioMobileCrmClientConfiguration),
    AgrioPublicCatalogsClientClientModule.forRoot(
      agrioPublicCatalogsClientConfiguration
    ),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [AppConfig.agrioConfig.apiBaseUrl],
        sendAccessToken: true,
      },
    }),
  ],

  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    AppConfigService,
    {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFn,
        multi: true,
        deps: [AppConfigService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function agrioMobileCrmClientConfiguration(): AgrioMobileCrmClientConfiguration {
  return new AgrioMobileCrmClientConfiguration({
    apiKeys: {},
    // basePath: `${environment.apiBaseUrl}/crm`,
    basePath: AppConfig.agrioConfig.apiBaseUrl,
    // basePath: 'https://yaver.agr.ist/mobile-crm-oas',
  });
}
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function agrioPublicCatalogsClientConfiguration(): AgrioPublicCatalogsClientConfiguration {
  return new AgrioPublicCatalogsClientConfiguration({
    apiKeys: {},
    // basePath: `${environment.apiBaseUrl}/public`,
    basePath: AppConfig.agrioConfig.publicBaseUrl,
    // basePath: 'https://yaver.agr.ist/portal-catalogs-oas',
  });
}
