/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { authPasswordFlowConfig } from '../auth-password-flow.config';

@Injectable()
export class AppConfigService {

  public appConfig: any={};
  constructor(
    private router: Router,
  ) {

  }

  async loadAppConfig() {
  await this.router.events.subscribe({
    next: (event: any) => {
      if (event.url) {

          if (event instanceof NavigationStart) {
            // console.log('AppComponent.Route change detected', event.url);
            if (!this.appConfig['tenantId'] || !this.appConfig['tenantId'].includes(event.url.toString().split('/')[1])) {
              this.appConfig['tenantId'] = event.url.toString().split('/')[1];
              authPasswordFlowConfig.issuer += this.appConfig['tenantId'];
              authPasswordFlowConfig.tokenEndpoint += this.appConfig['tenantId'] + '/protocol/openid-connect/token';
            }

        }

        if (event instanceof NavigationEnd) {
            // Hide progress spinner or progress bar
        }
      }

     },
    error: (err) => {
      console.error('AppComponent.Route change detected', err);
    },
  });
  }

  getConfig() {
    console.log(this.appConfig);

    return this.appConfig;
  }
}
