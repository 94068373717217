import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
const routes: Routes = [
{
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
},
{
    path: ':tenant',
    redirectTo: '/:tenant/login',
    pathMatch: 'full'
},
{
    path: ':tenant',
    // canActivate: [NoAuthGuard],
    // canActivateChild: [NoAuthGuard],
    // component: LayoutComponent, portalApp dan tenant url den al kullan.....http hata sayfası oluştur
    data: {
    layout: 'empty',
    },
    children: [
        {
            path: 'tabs',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
        },
        {
            path: 'intro-candidate',
            loadChildren: () => import('./pages/intro-candidate/intro-candidate.module').then(m => m.IntroCandidatePageModule)
        },
        {
            path: 'login',
            loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
        },
        {
            path: 'consent/:id',
            loadChildren: () => import('./pages/public-consent/public-consent.module').then( m => m.PublicConsentPageModule)
        },  {
          path: '**',
          loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
        },
    ]
}

];
@NgModule({
imports: [
RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
],
exports: [RouterModule]
})
export class AppRoutingModule {}

