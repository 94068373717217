/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import * as moment from 'moment';
import { of, Observable, interval, Subject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { environment } from 'src/environments/environment';
import { authPasswordFlowConfig } from '../auth-password-flow.config';
import { AppConfigService } from './app-config.json.service';
import { ToastService } from './toast.service';


@Injectable({
  providedIn: 'root',
})
export class OAuthUtilService {

  public tokenExpiredLeft$ = new Subject<number>();
  private credentials = { a: '', b: '' };
  public formsReady$ = new Subject<boolean>();
  private ngDestroyed$: ReplaySubject<boolean>;
  private timer;
  constructor(
    private oauthService: OAuthService,
    private toastService: ToastService,
    protected router: Router,
    private routes: Router,
    private appConfigService: AppConfigService,
  ) {
    this.formsReady$.next(false);
    this.oauthService.configure(authPasswordFlowConfig);
    this.oauthService.loadDiscoveryDocument().then().catch((err) => {
      console.error('Error on LoginPage.loadDiscoveryDocument : ',err);

      if (err.status === 404) {
        this.routes.navigate(['']);
        }
    });
    this.tokenEstimatedTimeTracker();
    this.startTracking();
  }

  startTracking() {
    this.ngDestroyed$ = new ReplaySubject(1);
    this.tokenExpiringTracker();
  }

  stopTracking() {
		this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  tokenEstimatedTimeTracker() {
    setInterval(() => {
        const l = this.expiryDate(this.oauthService.getAccessTokenExpiration());
          this.tokenExpiredLeft$.next(l);
    }, 1000);
  }

  tokenExpiringTracker() {
    this.tokenExpiredLeft$?.pipe(takeUntil(this.ngDestroyed$)).subscribe({
      next: (l) => {
        if (l < 1 ) {
          this.tokenExpired();
        }
      },
      error:()=>{}
    });
  }

  tokenRefresher() {
    this.tokenExpiredLeft$.subscribe({
      next: (l) => {
        if (l < 10 ) {
          // this.oauthService.refreshToken();
        }
      },
      error:()=>{}
    });
  }

  refreshTokenPageDependsRouting() {
    this.router.events.subscribe( (e) => {
      if (e instanceof NavigationEnd) {
        console.log('NavigationEnd : ', e.url);
        const l = this.expiryDate(this.oauthService.getAccessTokenExpiration());
        if (l > 0) {
          this.oauthService.refreshToken();
          // this.callRrefreshToken();
        } else {
          this.tokenExpired();
        }
      }
    });
  }

  tokenExpired() {
    console.error('Token Expired : ', moment().format('HH:mm:ss'));
    this.stopTracking();
    try {
      this.oauthService.refreshToken();
      this.startTracking();
    } catch (error) {
      console.error('Error on try refresh token when it expired. Error : ', error);
      this.cancelRefresh();
    }

    setTimeout(() => {
    const l = this.expiryDate(this.oauthService.getAccessTokenExpiration());
      console.log('Token Refresh : ', moment().format('HH:mm:ss') + ' / ' + l);
      if (!l || l < 0) {
        this.cancelRefresh();
      }
    }, 2000);
  }

  cancelRefresh() {
    this.stopTracking();
    this.routes.navigate([ this.appConfigService.getConfig().tenantId + '/login']);
  }

  callRrefreshToken(){
    this.refreshToken();
  }

  private async refreshToken(): Promise<any> {
    return new Promise((resolve, reject) => {
    this.oauthService
    .fetchTokenUsingPasswordFlowAndLoadUserProfile(this.credentials.a, this.credentials.b )
      .then(async (response: any) => {
        this.getAuthTokenInfo();
     })
      .catch(async (err) => {
      console.error('refresh token error : ', err);
      });
      resolve(true);
    });
  }

 private expiryDate(date_string) {
  var expiration = moment(date_string);
  var current_date = moment();
  var d = moment(expiration).diff(current_date, 'seconds');
  return d;
}

  public getAuthTokenInfo() {
  console.log("OAuthUtilService.token : ", {token: this.oauthService.getAccessToken()});
  console.log("OAuthUtilService.exp : ", this.oauthService.getAccessTokenExpiration());
  console.log("OAuthUtilService.token.exp_left : ", this.expiryDate(this.oauthService.getAccessTokenExpiration()));
  console.log("OAuthUtilService.hasValidAccessToken : ", this.oauthService.hasValidAccessToken());



}


  public setCredentials(a: string, b: string) {
    this.credentials.a = a;
    this.credentials.b = b;

  }
}
