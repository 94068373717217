import { Component ,HostListener} from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppConfigService } from './services/app-config.json.service';
import { ScreensizeService } from './services/screensize.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private appConfigService: AppConfigService,
    private screensizeService: ScreensizeService
  ) {

    this.initializeApp();

  }

  async initializeApp() {
   setTimeout(() => {
      const r = this.appConfigService.getConfig().tenantId;
    }, 0);


    this.platform.ready().then(() => {

      this.screensizeService.onResize(this.platform.width());
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }
}
