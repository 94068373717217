// This api will come in the next version

import { AuthConfig } from 'angular-oauth2-oidc';
import { AppConfig } from './core/config/app.config';

export const authPasswordFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: `${AppConfig.agrioConfig.authUrl}/realms/`,
  tokenEndpoint: `${AppConfig.agrioConfig.authUrl}/realms/`,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/index.html',

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'portal-client',

  // dummyClientSecret: 'geheim',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email',

  showDebugInformation: true,

  oidc: false,
};
