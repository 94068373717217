// import { Layout } from 'app/layout/layout.types';

// Types
// export type Scheme = 'auto' | 'dark' | 'light';
// export type Theme = 'default' | string;

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface FuseConfig {
  title: string;
  logoUrl: string;
  logoTextUrl: string;
  logoTextOnDarkUrl: string;
}

/**
 * Default configuration for the entire application. This object is used by
 * FuseConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 */
// export const appConfig: IAppConfig = {
//     layout: 'classy',
//     scheme: 'light',
//     theme : 'default'
// };

export class AppConfig {
  // We get the configuration from the 'window.configuration' property which as been set earlier by 'config/load.js'.
  private static value = (window as any).configuration;

  public static get agrioConfig(): any {
    // return AppConfig.value.agrioConfig;
    console.log('config', AppConfig.value);
    return {
      apiBaseUrl: AppConfig.value.apiBaseUrl,
      publicBaseUrl: AppConfig.value.publicBaseUrl,
      authUrl: AppConfig.value.authUrl,
      cdnUrl: AppConfig.value.cdnUrl,
    };
  }
}
